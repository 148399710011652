"use client";

import React from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import BookCover from "./BookCover";
import useUIStore from "@/store/useUIStore";
import { Book, Review } from "@/graphql/generated/types";
import Link from "next/link";
interface ReviewCarouselProps {
  className?: string;
  title: string;
  url?: string;
  reviews?: Review[];
  hideMore?: boolean;
  mobileVariant?: "carousel" | "grid";
  type?: "review" | "book";
}
const ReviewCarousel: React.FC<ReviewCarouselProps> = ({
  className,
  title,
  url,
  reviews,
  hideMore,
  mobileVariant = "grid"
}) => {
  const isSkeletonLoading = useUIStore(state => state.isSkeletonLoading);
  // const tempBooks = React.useMemo(() => {
  //   const clonedBooks: (Book | null)[] = [...books]
  //   while (clonedBooks.length < 7) {
  //     clonedBooks.push(null)
  //   }
  //   return clonedBooks
  // }, [books])
  if (reviews?.length === 0) {
    return null;
  }
  return <div className={cn("", className)} data-sentry-component="ReviewCarousel" data-sentry-source-file="ReviewCarousel.tsx">
      {isSkeletonLoading ? <Skeleton className="h-8 w-[300px] mb-5" /> : <ReviewCarouselHeader title={title} url={url} hideMore={hideMore} />}
      <div className="max-lg:hidden">
        <div className="grid max-lg:hidden grid-cols-3 md:grid-cols-5 gap-x-2 lg:grid-cols-7 xl:grid-cols-8">
          {reviews?.slice(0, 8).map((review, index) => <div key={index} className={cn("aspect-[1/1.5] h-full w-full overflow-hidden rounded-[4px]", index > 6 && "sm:max-xl:hidden")}>
              {isSkeletonLoading ? <Skeleton className="h-full w-full" /> : <Link href={review?.book && review?.user?.username ? `/books/${review?.book?.slug}/reviews/${review?.user?.username}` : `/books/${review?.book?.slug}`}>
                  <BookCover useDivInsteadOfLink sizes="154px" fallbackClassName="rounded-[4px]" book={review?.book as Book} className="h-full w-full rounded-[4px] object-cover object-center" />
                </Link>}
            </div>)}
        </div>
      </div>

      {/* Mobile View */}
      {mobileVariant === "grid" && <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:hidden gap-3">
          {reviews?.slice(0, Math.min(reviews.length, 9)).map((review, index) => <div key={index} className="aspect-[1/1.5] h-full w-full overflow-hidden rounded-[4px] sm:rounded-[8px]">
                {isSkeletonLoading ? <Skeleton className="h-full w-full" /> : <Link href={review?.book && review?.user?.username ? `/books/${review?.book?.slug}/reviews/${review?.user?.username}` : `/books/${review?.book?.slug}`}>
                    <BookCover useDivInsteadOfLink fallbackClassName="rounded-[8px]" sizes="109px" book={review?.book as Book} className="h-full w-full rounded-[8px] object-cover object-center" />
                  </Link>}
              </div>)}
        </div>}

      {/* {mobileVariant === "carousel" && (
        <MobileReviewCarousel books={books.slice(0, 10) as Book[]} />
       )} */}
    </div>;
};
export default React.memo(ReviewCarousel);
ReviewCarousel.displayName = "ReviewCarousel";
import { FaChevronRight } from "react-icons/fa";
interface ReviewCarouselHeaderProps {
  title: string;
  url?: string;
  hideMore?: boolean;
  urlText?: string;
}
const ReviewCarouselHeader: React.FC<ReviewCarouselHeaderProps> = ({
  title,
  url,
  hideMore,
  urlText
}) => {
  return <div className={cn("mb-5 max-sm:mb-4 flex max-sm:justify-between items-center gap-5")} data-sentry-component="ReviewCarouselHeader" data-sentry-source-file="ReviewCarousel.tsx">
      {url ? <Link className="text-xl sm:text-2xl font-semibold text-white" href={url ?? ""}>
          {title}
        </Link> : <h3 className="text-xl sm:text-2xl font-semibold text-white">
          {title}
        </h3>}
      {!hideMore && <Link href={url ?? ""} className="font-medium text-white flex sm:mt-0.5 items-center gap-1">
          <span className="max-sm:hidden">
            {urlText ? urlText : "See More"}
          </span>
          <span className="sm:hidden">{urlText ? urlText : "See All"}</span>
          {!urlText && <FaChevronRight className="max-sm:hidden" size={12} />}
        </Link>}
    </div>;
};