/* eslint-disable @typescript-eslint/no-explicit-any */
"use client";

import React, { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Badge } from "@/components/ui/badge";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import BookCover from "./BookCover";
import BookCarouselHeader from "./BookCarouselHeader";
import { PERMANENT_SHELVES } from "@/constants/PERMANENT_SHELVES";
import { formatToShortenNumber, kebabToCapitalCase } from "@/utils";
import { useQuery } from "@apollo/client";
import { MyBooksByStatusDocument, MyCountByStatusDocument, MyUsernameDocument, ReadingStatusEnum } from "@/graphql/generated/types";
import MobileShelvedBookCarousel from "./MobileShevedBookCarousel";
import { ScrollArea, ScrollBar } from "../ui/scroll-area";
interface ShelvedBookCarouselProps {
  className?: string;
  title: string;
  hideMore?: boolean;
  isLoggedIn: boolean;
}
const ShelvedBookCarousel: React.FC<ShelvedBookCarouselProps> = ({
  className,
  title,
  hideMore,
  isLoggedIn
}) => {
  // const isSkeletonLoading = useUIStore((state) => state.isSkeletonLoading)
  const [activeShelf, setActiveShelf] = useState<string>(PERMANENT_SHELVES[2].value);
  const {
    data: myUsernameData
  } = useQuery(MyUsernameDocument, {
    skip: !isLoggedIn
  });
  const {
    data: countsData
  } = useQuery(MyCountByStatusDocument, {
    fetchPolicy: "cache-and-network",
    skip: !isLoggedIn
  });
  const {
    data,
    loading
  } = useQuery(MyBooksByStatusDocument, {
    variables: {
      status: activeShelf as ReadingStatusEnum,
      page: 1,
      pageSize: 8
    },
    fetchPolicy: "cache-and-network",
    skip: !isLoggedIn
  });
  const books = data?.me?.libraryBooksByStatus?.items.map(item => item.book) || [];
  const getFilteredBooks = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tempBooks: any = [...books];
    while (tempBooks.length < 8) {
      tempBooks.push(null);
    }
    return {
      books: tempBooks
    };
  };
  const handleShelfChange = (shelfValue: string) => {
    setActiveShelf(shelfValue);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const countMapping: any = {
    [ReadingStatusEnum.CurrentlyReading]: countsData?.me?.currentlyReading?.pagination?.totalCount || 0,
    [ReadingStatusEnum.Read]: countsData?.me?.read?.pagination?.totalCount || 0,
    [ReadingStatusEnum.WantToRead]: countsData?.me?.wantToRead?.pagination?.totalCount || 0,
    // [ReadingStatusEnum.Priority]:
    //   countsData?.me?.priority?.pagination?.totalCount || 0,
    [ReadingStatusEnum.OnHold]: countsData?.me?.onHold?.pagination?.totalCount || 0,
    [ReadingStatusEnum.DidNotFinish]: countsData?.me?.didNotFinish?.pagination?.totalCount || 0
  };
  const shelvesWithCounts = PERMANENT_SHELVES.map(shelf => ({
    ...shelf,
    count: countMapping[shelf.value] || 0
  }));

  // Optionally, refetch when activeShelf changes
  // useEffect(() => {
  //   if (activeShelf !== "ALL") {
  //     refetch({ status: activeShelf, page: 1, pageSize: 7 })
  //   }
  // }, [activeShelf, refetch])

  return <div className={cn("w-full", className)} data-sentry-component="ShelvedBookCarousel" data-sentry-source-file="ShelvedBookCarousel.tsx">
      <BookCarouselHeader title={title} url={`/users/${myUsernameData?.me?.username}/library`} hideMore={hideMore} data-sentry-element="BookCarouselHeader" data-sentry-source-file="ShelvedBookCarousel.tsx" />
      <div
    // opts={{
    //   align: "start",
    //   slidesToScroll: "auto",
    //   watchDrag: false,
    // }}
    className="max-lg:hidden">
        <Tabs value={activeShelf} onValueChange={handleShelfChange} defaultValue={PERMANENT_SHELVES[2].value} data-sentry-element="Tabs" data-sentry-source-file="ShelvedBookCarousel.tsx">
          <TabsList className="bg-transparent p-0" data-sentry-element="TabsList" data-sentry-source-file="ShelvedBookCarousel.tsx">
            {shelvesWithCounts.slice(1).map(shelf => <TabsTrigger key={shelf.value} value={shelf.value} className={cn("mb-6 p-0 pr-8 text-base font-medium text-white data-[state=active]:bg-transparent data-[state=active]:text-[#94A8F8]")}>
                {kebabToCapitalCase(shelf.label)}{" "}
                <Badge className="ml-1 rounded-[107px] bg-[#202020] px-2 py-0 text-sm font-normal text-inherit transition-none hover:bg-[#202020]">
                  {formatToShortenNumber(shelf.count)}
                </Badge>
              </TabsTrigger>)}
          </TabsList>

          <TabsContent value={activeShelf} className="mt-0" data-sentry-element="TabsContent" data-sentry-source-file="ShelvedBookCarousel.tsx">
            <div className="grid max-lg:hidden grid-cols-3 md:grid-cols-5 gap-x-2 lg:grid-cols-7 xl:grid-cols-8">
              {getFilteredBooks().books.map((book: any, index: number) => <div style={{
              filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.35))"
            }} key={index} className={cn("aspect-[1/1.5] h-full w-full overflow-hidden rounded-[4px]", index > 6 && "sm:max-xl:hidden")}>
                  {loading ? <Skeleton className="h-full w-full" /> : book ? <BookCover fallbackClassName="rounded-[4px]" sizes="154px" loading="eager" book={book} className="h-full w-full rounded-[4px] object-cover object-center" /> : <FallbackImage />}
                </div>)}
            </div>
          </TabsContent>
        </Tabs>
      </div>

      {/* Mobile View */}
      <div className="lg:hidden w-full">
        <Tabs value={activeShelf} onValueChange={handleShelfChange} defaultValue={PERMANENT_SHELVES[2].value} data-sentry-element="Tabs" data-sentry-source-file="ShelvedBookCarousel.tsx">
          <ScrollArea data-sentry-element="ScrollArea" data-sentry-source-file="ShelvedBookCarousel.tsx">
            <div className="sm:justify-start">
              <TabsList className="snap-x flex-nowrap snap-mandatory scroll-smooth w-full flex sm:justify-start flex-1  max-lg:mb-3 max-lg:gap-2 bg-transparent p-0" data-sentry-element="TabsList" data-sentry-source-file="ShelvedBookCarousel.tsx">
                {shelvesWithCounts.slice(1).map(shelf => <TabsTrigger key={shelf.value} value={shelf.value} className="lg:mb-5 sm:justify-start snap-start flex-shrink-0 max-lg:px-4 lg:border-b-[4px] max-lg:h-[37px] lg:border-b-transparent max-lg:bg-[#232533] py-2 max-lg:rounded-[100px] lg:bg-none lg:p-0 lg:pb-5 text-sm font-base lg:text-base lg:font-normal text-white lg:text-[#e6e6fa]/90 lg:data-[state=active]:border-b-[#5069ce] lg:data-[state=active]:bg-transparent lg:data-[state=active]:font-semibold data-[state=active]:font-medium data-[state=active]:bg-[#5069ce] data-[state=active]:text-white">
                    {kebabToCapitalCase(shelf.label)}{" "}
                    {/* <Badge className="ml-1 rounded-[107px] bg-[#202020] px-2 py-0 text-sm font-normal text-inherit transition-none hover:bg-[#202020]">
                     {formatToShortenNumber(shelf.count)}
                     </Badge> */}
                  </TabsTrigger>)}
              </TabsList>
            </div>

            <ScrollBar orientation="horizontal" hideThumb data-sentry-element="ScrollBar" data-sentry-source-file="ShelvedBookCarousel.tsx" />
          </ScrollArea>

          <TabsContent value={activeShelf} className="mt-0" data-sentry-element="TabsContent" data-sentry-source-file="ShelvedBookCarousel.tsx">
            {/* Use MobileBookCarousel with react-slick */}
            <MobileShelvedBookCarousel items={getFilteredBooks().books.slice(0, 9)} loading={loading} renderItem={(book: any) => <div className="px-0 lg:hidden">
                  <div className="aspect-[1/1.5] w-full overflow-hidden rounded-[4px] lg:rounded-[8px]">
                    {loading ? <Skeleton className="h-full w-full" /> : book ? <BookCover sizes="109px" fallbackClassName="rounded-[8px]" mobileFallbackSize={28} loading="eager" book={book} className="h-full w-full rounded-[8px] object-cover object-center" /> : <FallbackImage />}
                  </div>
                </div>} data-sentry-element="MobileShelvedBookCarousel" data-sentry-source-file="ShelvedBookCarousel.tsx" />
          </TabsContent>
        </Tabs>
      </div>
    </div>;
};
export default React.memo(ShelvedBookCarousel);
ShelvedBookCarousel.displayName = "ShelvedBookCarousel";
const FallbackImage: React.FC = () => <div className="flex h-full w-full items-center justify-center rounded-[4px] bg-[#272834]" data-sentry-component="FallbackImage" data-sentry-source-file="ShelvedBookCarousel.tsx">
    {/* <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
     >
      <path
        d="M29 54.375C43.0142 54.375 54.375 43.0142 54.375 29C54.375 14.9858 43.0142 3.625 29 3.625C14.9858 3.625 3.625 14.9858 3.625 29C3.625 43.0142 14.9858 54.375 29 54.375Z"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="3.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.125 29H39.875"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="3.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 18.125V39.875"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="3.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
     </svg> */}
  </div>;