"use client";

import { cn } from "@/lib/utils";
import React, { ReactNode } from "react";
// import Slider from "react-slick"
import { Carousel, CarouselContent, CarouselItem } from "../ui/carousel";
export interface MobileCarouselItem {
  id?: string | null;
}
interface CarouselProps<T extends MobileCarouselItem> {
  items: T[];
  renderItem: (item: T) => ReactNode;
  slidesToShow?: number;
  slidesToScroll?: number;
  partialVisibleFraction?: number;
  className?: string;
}
const MobileCarousel = <T extends MobileCarouselItem,>({
  items,
  renderItem,
  slidesToShow = 3,
  // slidesToScroll = 1,
  partialVisibleFraction = 0.35,
  className
}: CarouselProps<T>) => {
  const actualSlidesToShow = items?.length < slidesToShow + partialVisibleFraction ? items?.length : slidesToShow + partialVisibleFraction;
  const gridCols = `grid-cols-${slidesToShow}`;
  if (items?.length <= slidesToShow) {
    return <div className={cn("grid grid-cols-2 gap-4 pr-5", items.length === slidesToShow && "gap-3 grid-cols-3", className, gridCols, items.length === 2 && "max-[420px]:grid-cols-2")}>
        {items.map(item => <div key={item.id} className="px-0">
            {renderItem(item)}
          </div>)}
      </div>;
  }
  const slideFlexBasis = `${100 / actualSlidesToShow}%`;
  return <div className={className} data-sentry-component="MobileCarousel" data-sentry-source-file="MobileCarousel.tsx">
      <Carousel opts={{
      loop: false,
      dragFree: true
    }} data-sentry-element="Carousel" data-sentry-source-file="MobileCarousel.tsx">
        <CarouselContent className="-ml-2.5" data-sentry-element="CarouselContent" data-sentry-source-file="MobileCarousel.tsx">
          {items.map(item => <CarouselItem key={item.id} className="pr-0 pl-2.5" style={{
          flexBasis: slideFlexBasis
        }}>
              {renderItem(item)}
            </CarouselItem>)}
        </CarouselContent>
      </Carousel>
    </div>;

  // return (
  //   <div className={className}>
  //     <Slider {...settings} className="overflow-hidden">
  //       {items.map((item) => (
  //         <div key={item.id} className="px-0">
  //           {renderItem(item)}
  //         </div>
  //       ))}
  //     </Slider>
  //   </div>
  // )
};
export default MobileCarousel;