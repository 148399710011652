"use client";

import React from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import BookCover from "./BookCover";
import BookCarouselHeader from "./BookCarouselHeader";
import useUIStore from "@/store/useUIStore";
import { Book } from "@/graphql/generated/types";
import { MobileBookCarousel } from "./MobileBookCarousel";
interface BookCarouselProps {
  className?: string;
  title: string;
  url?: string;
  books: Book[];
  hideMore?: boolean;
  mobileVariant?: "carousel" | "grid";
}
const BookCarousel: React.FC<BookCarouselProps> = ({
  className,
  title,
  url,
  books,
  hideMore,
  mobileVariant = "grid"
}) => {
  const isSkeletonLoading = useUIStore(state => state.isSkeletonLoading);
  // const tempBooks = React.useMemo(() => {
  //   const clonedBooks: (Book | null)[] = [...books]
  //   while (clonedBooks.length < 7) {
  //     clonedBooks.push(null)
  //   }
  //   return clonedBooks
  // }, [books])

  if (books?.length === 0) {
    return null;
  }
  return <div className={cn("", className)} data-sentry-component="BookCarousel" data-sentry-source-file="BookCarousel.tsx">
      {isSkeletonLoading ? <Skeleton className="h-8 w-[300px] mb-5" /> : <BookCarouselHeader title={title} url={url} hideMore={hideMore} />}
      <div className="max-lg:hidden">
        <div className="grid max-lg:hidden grid-cols-3 md:grid-cols-5 gap-x-2 lg:grid-cols-7 xl:grid-cols-8">
          {books?.slice(0, 8).map((book, index) => <div key={index} className={cn("aspect-[1/1.5] h-full w-full overflow-hidden rounded-[4px]", index > 6 && "sm:max-xl:hidden"
        // index > 6 && "xl:max-2xl:hidden"
        )}>
              {isSkeletonLoading ? <Skeleton className="h-full w-full" /> : book ? <BookCover fallbackClassName="rounded-[4px]" sizes="154px" book={book} className="h-full w-full rounded-[4px] object-cover object-center" /> : null}
            </div>)}
        </div>
      </div>

      {/* Mobile View */}
      {mobileVariant === "grid" && <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:hidden gap-3">
          {books?.slice(0, Math.min(books.length, 9)).map((book, index) => <div key={index} className="aspect-[1/1.5] h-full w-full overflow-hidden rounded-[4px] sm:rounded-[8px]">
              {isSkeletonLoading ? <Skeleton className="h-full w-full" /> : book ? <BookCover sizes="109px" fallbackClassName="rounded-[8px]" book={book} className="h-full w-full rounded-[8px] object-cover object-center" /> : null}
            </div>)}
        </div>}

      {mobileVariant === "carousel" && <MobileBookCarousel books={books?.slice(0, 10) as Book[]} />}
    </div>;
};
export default React.memo(BookCarousel);
BookCarousel.displayName = "BookCarousel";