"use client";

import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Book, ReadingStatusEnum, Shelf, User } from "@/graphql/generated/types";
import useSetReadingStatus from "@/hooks/useSetReadingStatus";
import { getStatusLabel, getStatusIcon } from "@/utils/statusUtils";
import { StatusShelvesDropdown } from "./StatusShelvesDropdown";
// import { toast } from "sonner"
// import { useResponsive } from "@/hooks/useResponsive"

const AddToShelfButton = ({
  initialStatus,
  initialShelves,
  bookId,
  className,
  buttonClassName,
  selectedClassName,
  triggerClassName,
  commitOnSelection,
  onStatusChange,
  username
}: {
  initialStatus?: ReadingStatusEnum | null;
  initialShelves: Shelf[];
  bookId: Book["id"];
  className?: string;
  buttonClassName?: string;
  triggerClassName?: string;
  selectedClassName?: string;
  commitOnSelection?: boolean;
  username: User["username"];
  onStatusChange?: (status: ReadingStatusEnum, selectedShelves: string[], unselectedShelves: string[]) => void;
}) => {
  const [status, setStatus] = useState<ReadingStatusEnum | null>(initialStatus || null);
  useEffect(() => {
    setStatus(initialStatus || null);
  }, [initialStatus]);

  // const { isMobile } = useResponsive()

  const {
    setStatus: setReadingStatus
  } = useSetReadingStatus(username as string, [bookId as string]);
  const handleButtonClick = async () => {
    if (!status) {
      const newStatus = ReadingStatusEnum.WantToRead;

      // Optimistic UI update
      setStatus(newStatus);

      // toast.success(`Book has been marked as "${getStatusLabel(newStatus)}".`, {
      //   style: {
      //     backgroundColor: "#5069ce",
      //     color: "white",
      //     fontSize: "16px",
      //   },
      // })

      try {
        await setReadingStatus(newStatus);
      } catch (e) {
        // Revert status if there was an error

        if (e instanceof Error) {
          setStatus(null);
          // toast.error("Failed to update status. Please try again.", {
          //   style: {
          //     backgroundColor: "#ff4d4f",
          //     color: "white",
          //     fontSize: "16px",
          //   },
          // })
        }
      }
    } else {
      // toast(`Book is already marked as "${getStatusLabel(status)}".`, {
      //   style: {
      //     backgroundColor: "#5069ce",
      //     color: "white",
      //     fontSize: "16px",
      //   },
      // })
    }
  };
  const statusLabel = getStatusLabel(status || ReadingStatusEnum.WantToRead);
  const displayLabel = buttonClassName && statusLabel.length > 13 ? statusLabel.slice(0, 13) + "..." : statusLabel;
  const statusIcon = getStatusIcon(status || ReadingStatusEnum.WantToRead, !status); // White icon

  return <div className={cn("flex h-[46px] bg-transparent", className)} data-sentry-component="AddToShelfButton" data-sentry-source-file="AddToShelfButton.tsx">
      <Button type="button" variant={status ? "secondary" : "primary"} className={cn("h-full flex items-center gap-2 justify-start cursor-pointer w-[160px] max-sm:rounded-l-[4px] max-sm:rounded-r-none rounded-r-none rounded-l-[4px] bg-[#5069ce] py-3 sm:pl-4 pl-3 pr-1.5 sm:text-base font-medium sm:font-normal leading-[22px] text-white", status && "bg-[#272834] cursor-default hover:bg-[#272834] active:bg-[#272834]", status && selectedClassName, buttonClassName)} onClick={handleButtonClick} data-sentry-element="Button" data-sentry-source-file="AddToShelfButton.tsx">
        {statusIcon}
        <span style={{
        filter: "drop-shadow(0px 3.04px 3.04px rgba(0, 0, 0, 0.25))"
      }} className="text-base text-white">
          {displayLabel}
        </span>
      </Button>
      <StatusShelvesDropdown
    // statuses={PERMANENT_SHELVES.slice(1)}
    username={username} initialShelves={initialShelves} initialStatus={status} bookIds={[bookId as string]} onStatusChange={(newStatus, selectedShelves, unselectedShelves) => {
      setStatus(newStatus);
      if (onStatusChange) onStatusChange(newStatus, selectedShelves, unselectedShelves);
    }} commitOnSelection={commitOnSelection}
    // onStatusChange={(newStatus) => {
    //   setStatus(newStatus)
    //   // toast.success(`Set status to "${getStatusLabel(newStatus)}".`, {
    //   //   style: {
    //   //     backgroundColor: "#5069ce",
    //   //     color: "white",
    //   //     fontSize: "16px",
    //   //   },
    //   // })
    // }}
    trigger={<Button type="button" variant="primary" className={cn("flex w-10 h-full items-center ring-0 focus:border-l-transparent max-sm:rounded-l-none max-sm:rounded-r-[4px] rounded-l-none rounded-r-[4px] border-l border-l-white/40 bg-[#5069ce] px-4 py-3", status && "border-l-transparent ", triggerClassName)}>
            <svg width="8" height="22" viewBox="0 0 8 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2117_800)">
                <path d="M0 9.16667L4 13.75L8 9.16667H0Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_2117_800">
                  <rect width="22" height="8" fill="white" transform="matrix(0 -1 1 0 0 22)" />
                </clipPath>
              </defs>
            </svg>
          </Button>} data-sentry-element="StatusShelvesDropdown" data-sentry-source-file="AddToShelfButton.tsx" />
    </div>;
};
export default AddToShelfButton;