"use client";

import React, { ReactNode } from "react";
import { Carousel, CarouselContent, CarouselItem } from "../ui/carousel";
import { useResponsive } from "@/hooks/useResponsive";
interface MobileShelvedBookCarouselProps<T> {
  items: T[];
  loading?: boolean;
  renderItem: (item: T, index: number) => ReactNode;
}
const MobileShelvedBookCarousel = <T extends {
  id?: string | number;
},>({
  items,
  renderItem
}: MobileShelvedBookCarouselProps<T>) => {
  const {
    isTablet
  } = useResponsive();
  const slidesToShow = isTablet ? 4 : 3;
  const partialVisibleFraction = isTablet ? 0.75 : 0.35;
  const actualSlidesToShow = items?.length < slidesToShow + partialVisibleFraction ? items?.length : slidesToShow + partialVisibleFraction;
  const slideFlexBasis = `${100 / actualSlidesToShow}%`;
  return <Carousel opts={{
    loop: false,
    dragFree: true
  }} data-sentry-element="Carousel" data-sentry-component="MobileShelvedBookCarousel" data-sentry-source-file="MobileShevedBookCarousel.tsx">
      <CarouselContent className="-ml-2.5" data-sentry-element="CarouselContent" data-sentry-source-file="MobileShevedBookCarousel.tsx">
        {items.map((item, index) => <CarouselItem key={index} className="pr-0 pl-2.5" style={{
        flexBasis: slideFlexBasis
      }}>
            {renderItem(item, index)}
          </CarouselItem>)}
      </CarouselContent>
    </Carousel>;
};
export default MobileShelvedBookCarousel;