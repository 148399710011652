"use client";

import { cn } from "@/lib/utils";
import React from "react";
import { Card, CardContent } from "@/components/ui/card";
import TrendingReviewsThisWeekCard from "../shared/TrendingReviewsThisWeekCard";
import { Skeleton } from "../ui/skeleton";
import { TrendingReviewsWeeklyDocument, User } from "@/graphql/generated/types";
import { useSuspenseQuery } from "@apollo/client";
import Link from "next/link";
const TrendingReviewsThisWeek = ({
  className,
  username
}: {
  className?: string;
  username: User["username"];
}) => {
  const {
    data
  } = useSuspenseQuery(TrendingReviewsWeeklyDocument, {
    // fetchPolicy: "cache-and-network",
  });
  const reviews = data?.trendingReviews?.items?.slice(0, 5) || [];
  const loading = false;
  return <div className={cn(" ", className)} data-sentry-component="TrendingReviewsThisWeek" data-sentry-source-file="TrendingReviewsThisWeek.tsx">
      <div className="lg:mb-3 lg:border-b border-[#98c6f4]/20 pb-4 sm:pb-2 lg:pb-6 flex gap-5 items-center">
        {loading ? <Skeleton className="h-12 max-sm:hidden w-[400px]" /> : <>
            <Link href={`/reviews`} className="text-xl font-bold sm:text-2xl sm:font-semibold leading-8 text-white">
              Trending Reviews This Week
            </Link>

            {/* <Link
              href={`/reviews`}
              className="font-medium text-white flex sm:mt-0.5 items-center gap-1"
             >
              <span className="max-sm:hidden">See More</span>
              <span className="font-medium sm:hidden text-white flex sm:mt-0.5 items-center gap-1">
                See All
              </span>
             </Link> */}
          </>}
      </div>
      <Card className="shadow-none " data-sentry-element="Card" data-sentry-source-file="TrendingReviewsThisWeek.tsx">
        <CardContent className="sm:divide-y max-sm:space-y-4  divide-[#98c6f4]/20 p-0 sm:pr-0" data-sentry-element="CardContent" data-sentry-source-file="TrendingReviewsThisWeek.tsx">
          {reviews.map(review => {
          return <TrendingReviewsThisWeekCard key={review?.id} username={username} showSeparator review={review} />;
        })}
        </CardContent>
      </Card>
    </div>;
};
export default TrendingReviewsThisWeek;