"use client";

import * as React from "react";
import Link from "next/link";
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from "@/components/ui/tooltip";
import { Skeleton } from "../ui/skeleton";
export type Author = {
  name?: string | null;
  slug?: string | null;
};
interface AuthorNamesProps {
  authors?: Author[];
  maxChars?: number; // default is 40
  className?: string;
  useDivInsteadOfLink?: boolean;
  showPrefix?: boolean;
}

/**
 * AuthorNames will:
 * 1. Display as many full author names as fit within maxChars.
 * 2. If the first name alone exceeds maxChars, it truncates that one name.
 * 3. The rest of the authors (those that can't fully fit) go into a ShadCN tooltip.
 * 4. The component is unstyled so it can inherit parent styles.
 */
export function AuthorNames({
  authors,
  maxChars = 32,
  useDivInsteadOfLink = false,
  className = "",
  showPrefix = false
}: AuthorNamesProps) {
  const [displayAuthors, setDisplayAuthors] = React.useState<Author[]>([]);
  const [tooltipAuthors, setTooltipAuthors] = React.useState<Author[]>([]);
  const [truncatedFirstAuthor, setTruncatedFirstAuthor] = React.useState<string | null>(null);
  const Container = useDivInsteadOfLink ? "span" : Link;
  React.useEffect(() => {
    if (!authors || authors.length === 0) {
      setDisplayAuthors([]);
      setTooltipAuthors([]);
      setTruncatedFirstAuthor(null);
      return;
    }
    const result = calculateAuthorsToDisplay(authors, maxChars);
    setDisplayAuthors(result.displayed);
    setTooltipAuthors(result.remaining);
    setTruncatedFirstAuthor(result.firstAuthorTruncated);
  }, [authors, maxChars]);

  // // Utility function
  // function truncate(str: string, limit: number): string {
  //   if (str.length <= limit) return str
  //   return str.slice(0, limit - 1) + "…"
  // }

  if (!authors?.length) {
    return <Skeleton className="h-4 w-32 bg-[#232533]" />;
  }
  return <div className={className} data-sentry-component="AuthorNames" data-sentry-source-file="AuthorNames.tsx">
      {displayAuthors.length > 0 ? <>
          {showPrefix && <span>by </span>}
          {displayAuthors.map((author, index) => {
        // If the first author had to be truncated
        if (index === 0 && truncatedFirstAuthor) {
          return <span key={author.slug}>
                  <Container href={`/authors/${author.slug}`}>
                    {truncatedFirstAuthor}
                  </Container>
                  {index < displayAuthors.length - 1 && ", "}
                </span>;
        } else {
          return <span key={author.slug} className="no-underline">
                  <Container href={`/authors/${author.slug}`} className="hover:no-underline">
                    {author.name}
                  </Container>
                  {index < displayAuthors.length - 1 && ", "}
                </span>;
        }
      })}
        </> : null}

      {tooltipAuthors.length > 0 ? <TooltipProvider>
          <Tooltip delayDuration={10}>
            {/* 
             For a better mobile experience, you might want a 'Popover'
             instead of a Tooltip, or a custom `open` management so it 
             doesn't close on click/tap. This is the basic ShadCN approach.
             */}
            <TooltipTrigger className="cursor-pointer ml-1">
              +{tooltipAuthors.length}
            </TooltipTrigger>
            <TooltipContent className="rounded-[8px] opacity-100 py-3 px-4 z-[120] max-w-[240px] bg-[#1d1e2f]"
        // Some suggestions so it stays open on mobile tap:
        onPointerDown={e => e.stopPropagation()} onClick={e => e.stopPropagation()}>
              {tooltipAuthors.map((author, index) => <Link key={author?.slug} className="text-xs leading-5 font-normal text-white/80 hover:underline p-0" href={`/authors/${author.slug}`}>
                  {index < tooltipAuthors.length - 1 ? `${author?.name}, ` : author?.name}
                </Link>)}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider> : null}
    </div>;
}

/**
 * Calculates which authors can fit entirely within maxChars.
 * If the first author's name alone doesn't fit, we truncate it.
 * We treat each subsequent author in full or not at all.
 */
function calculateAuthorsToDisplay(authors: Author[], maxChars: number) {
  let remainingChars = maxChars;
  const displayed: Author[] = [];
  let remaining: Author[] = [];
  let firstAuthorTruncated: string | null = null;
  const delimiter = ", ";
  let i = 0;
  for (; i < authors?.length; i++) {
    const authorName = authors[i].name as string;
    // If we're at the first author and that alone doesn't fit, truncate
    if (i === 0 && authorName?.length > remainingChars) {
      firstAuthorTruncated = authorName?.slice(0, remainingChars - 1) + "…";
      displayed.push(authors[i]);
      // We consider the entire limit consumed in this scenario,
      // or set remainingChars to 0 so no more authors can fit.
      remainingChars = 0;
    } else {
      // account for length of name + possible delimiter (if it's not the first)
      const lengthNeeded = i === 0 ? authorName?.length : authorName?.length + delimiter.length;
      if (lengthNeeded <= remainingChars) {
        displayed.push(authors[i]);
        remainingChars -= lengthNeeded;
      } else {
        // As soon as we hit an author that doesn't fit fully, break out
        break;
      }
    }
  }
  if (i < authors.length) {
    remaining = authors.slice(i);
  }
  return {
    displayed,
    remaining,
    firstAuthorTruncated
  };
}