"use client";

import Link from "next/link";
import { formatToShortenNumber } from "@/utils";
// import { HiMiniStar } from "react-icons/hi2"
import React from "react";
import { Card, CardContent } from "../ui/card";
import { cn } from "@/lib/utils";
import { Skeleton } from "../ui/skeleton";
import ReviewActionsMenu from "../reviews/ReviewActionsMenu";
import AddToShelfButton from "./AddToShelfButton";
// import ReadMoreText from "./ReadMoreText"
import BookCover from "./BookCover";
import LikeButton from "../reviews/LikeButton";
import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Book, ReadingStatusEnum, ReviewFieldsFragment, Shelf, User } from "@/graphql/generated/types";
import { useUser } from "@/contexts/user";
import ShareButton from "./ShareButton";
import ReviewReadMore from "../reviews/ReviewReadMore";
import { getHtmlContent, getTruncatedHtml } from "@/utils/readMoreUtils";
import { useResponsive } from "@/hooks/useResponsive";
import { calendarCustom } from "@/utils/dayjs";
import { DateTooltip } from "./DateTooltip";
import UserAvatar from "./UserAvatar";
import DisplayRatings from "../reviews/DisplayRatings";
// import GenerateReviewImageButton from "../reviews/GenerateReviewImageButton"

dayjs.extend(relativeTime);
dayjs.extend(calendarCustom);
const TrendingReviewsThisWeekCard = ({
  review,
  username,
  variant = "default",
  className,
  bookCoverClassName,
  textClassName,
  showSeparator = false,
  hideAuthor = false
}: {
  review?: ReviewFieldsFragment | null;
  hideAuthor?: boolean;
  variant?: "default" | "compact";
  username: User["username"] | null;
  bookCoverClassName?: string;
  className?: string;
  showSeparator?: boolean;
  textClassName?: string;
}) => {
  const {
    data: userData
  } = useQuery(gql`
      query User($id: ID!, $bookId: ID!) {
        user(id: $id) {
          id
          ratingForBook(bookId: $bookId) {
            rating
            userId
            bookId
          }
        }
      }
    `, {
    variables: {
      id: review?.user?.id,
      bookId: review?.book?.id
    }
  });
  const {
    isTablet,
    isMobile
  } = useResponsive();
  const {
    user: userAuth
  } = useUser();
  const displayLength = isTablet || isMobile ? 330 : 428;
  const fullHtml = getHtmlContent(review?.content);
  const truncatedReview = getTruncatedHtml(fullHtml, displayLength).html;
  const myUserId = userAuth?.id;
  if (!review) return null;
  return <Card className={cn(" rounded-none max-sm:rounded-[8px] max-sm:bg-[#232533] shadow-none ", variant === "default" && "pt-5 pb-6 sm:py-5 lg:pl-4 lg:pr-0 max-sm:px-4 sm:first:pt-1", variant === "compact" && "max-sm:px-3 max-sm:pt-4 max-sm:pb-5 max-sm:bg-[#191a27]", className)} data-sentry-element="Card" data-sentry-component="TrendingReviewsThisWeekCard" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
      <CardContent className={cn("flex w-full max-sm:flex-col gap-4 p-0", variant === "compact" && "max-sm:gap-1")} data-sentry-element="CardContent" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
        <div className={cn("flex max-md:gap-4 md:gap-5 sm:px-0 max-sm:items-start sm:py-0", variant === "compact" && "md:gap-6 gap-3 sm:max-md:py-0 sm:py-5")}>
          <div className={cn("sm:h-[144px] sm:w-[96px] h-[120px] w-20 flex-shrink-0 overflow-hidden rounded-[4px] sm:rounded-[8px]", variant === "compact" && "w-[70px] h-[105px] sm:h-[120px] sm:w-20", bookCoverClassName)}>
            {!review?.book ? <Skeleton className="h-full w-full" /> : <BookCover fallbackClassName="rounded-[4px] sm:rounded-[8px]" sizes={showSeparator ? "(max-width: 640px) 80px, (max-width: 1024px) 100px, 96px" : "(max-width: 640px) 80px, (max-width: 1024px) 100px, 142px"} book={review?.book as Book} fallBackSize={52} className="h-full rounded-[4px] sm:rounded-[8px] object-cover object-center" />}
          </div>

          <div className="flex flex-col">
            {/* <span
             className={cn(
              "text-lg text-[#94A8F8]",
              type === "Deep Dive" && "text-[#6BC589]",
              type === "funny" && "text-star"
             )}
             >
             # {capitalize(type)}
             </span> */}
            {!review?.book ? <Skeleton className="h-5 w-full" /> : <Link href={review?.book?.slug ? `/books/${review?.book?.slug}` : ""} className=" underline-offset-2 hover:underline">
                {" "}
                <h4 className={cn("line-clamp-2 mb-2 text-xl font-extrabold max-sm:leading-6 sm:font-bold text-white", variant === "compact" && "text-lg leading-[22px] md:leading-[26px] max-md:mb-2 font-bold md:font-semibold")}>
                  {review?.book?.title}
                </h4>
              </Link>}

            {!review?.user ? <Skeleton className="h-4 w-full mt-2" /> : <div className="flex flex-col gap-3">
                <div className={cn("flex items-center gap-2 sm:gap-[13px] text-white", showSeparator && "gap-1 sm:gap-1", variant === "compact" && "gap-1.5")}>
                  {!hideAuthor && <div className={cn("flex items-center justify-center gap-1", variant === "compact" && "gap-1.5 items-center")}>
                      <Link href={`/users/${review?.user?.username ?? ""}`}>
                        <UserAvatar user={review?.user} sizes="(max-width: 640px) 26px, 32px" className={cn("size-[26px] sm:h-7 sm:w-7", variant === "compact" && "h-6 w-6")} fallbackSize={20} />
                        {/* <Avatar>
                          <AvatarImage
                            width={32}
                            height={32}
                            src={avatarUrl}
                            alt="author"
                          />
                          <AvatarFallback fallbackSize={20} />
                         </Avatar> */}
                      </Link>
                      <Link className="truncate max-w-[133px] sm:max-w-[240px]" href={`/users/${review?.user?.username ?? ""}`}>
                        <span className={cn("sm:text-base text-sm font-bold", variant === "compact" && "text-[13px] max-sm:leading-4 max-sm:font-medium sm:text-sm text-[#dadada]")}>
                          {review?.user?.displayName}
                        </span>
                      </Link>
                    </div>}

                  {userData?.user?.ratingForBook?.rating ? <>
                      {showSeparator && <span className={cn("text-white/40 text-[7px]", variant === "compact" && "max-sm:text-sm max-sm:text-[#dadada]")}>
                          •
                        </span>}

                      {/* <div
                        className={cn(
                          "flex items-center gap-0.5 sm:gap-[3px]",
                          variant === "compact" && "sm:gap-0.5"
                        )}
                       >
                        <span
                          className={cn(
                            "text-sm sm:text-base font-bold",
                            variant === "compact" &&
                              "text-[13px] max-sm:font-medium max-sm:leading-4 sm:text-base font-bold text-white"
                          )}
                        >
                          {userData?.user?.ratingForBook?.rating ?? 0}
                        </span> */}
                      <DisplayRatings iconClassName={cn(variant === "compact" && "size-[12px]")} rating={userData?.user?.ratingForBook?.rating ?? 0} />
                      {/* <HiMiniStar
                          color="#FFA432"
                          className={cn(
                            "sm:size-[16px]",
                            variant === "compact" && "size-[12px]"
                          )}
                        />
                       </div> */}
                    </> : ""}

                  {showSeparator && <span className="text-white/40 max-sm:hidden text-[7px]">
                      •
                    </span>}

                  <DateTooltip date={review?.insertedAt}>
                    <span className={cn("-ml-0.5 max-sm:hidden text-base leading-[18px] font-light", variant === "compact" && "text-sm ml-0 leading-[20px]")}>
                      {dayjs(review?.insertedAt).calendarCustom()}
                    </span>
                  </DateTooltip>
                </div>

                {isMobile && <div className="sm:hidden">
                    <AddToShelfButton username={username} initialShelves={review?.book?.myShelves as Shelf[]} buttonClassName="w-[160px] justify-start" selectedClassName="max-sm:bg-white/[6%]" initialStatus={review?.book?.myReadingStatus?.status as ReadingStatusEnum} bookId={review?.book?.id} />
                  </div>}
              </div>}

            <div className={cn("max-sm:hidden", variant === "default" && "-mt-2")}>
              {false ? <Skeleton className="mt-2.5 h-[174px] w-full" /> : <ReviewReadMore isSpoiler={review?.isSpoiler} content={review?.content} mobileLength={330} desktopLength={428} preTruncatedHtml={truncatedReview} preFullHtml={fullHtml} className={cn("mb-3 mt-2.5 text-base font-normal text-[#f4f4f4] lg:text-[#E6E6FA]/90", variant === "compact" && "lg:text-[#e6e6fa]/90 sm:text-sm lg:leading-5 -mt-1", textClassName)} />}
              <div className={cn("flex items-center h-8 gap-[34px] text-xs sm:text-sm sm:font-normal font-semibold text-white/70", variant === "default" && "-mt-2", variant === "compact" && "gap-2 text-[#e6e6fa]/90")}>
                {/* <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.24909 14.6528L8.24837 14.6521C6.30729 12.8919 4.7364 11.4645 3.64494 10.1292C2.55898 8.80059 2 7.62437 2 6.375C2 4.33424 3.59055 2.75 5.625 2.75C6.77885 2.75 7.89456 3.28973 8.62058 4.13564L9 4.57771L9.37942 4.13564C10.1054 3.28973 11.2212 2.75 12.375 2.75C14.4095 2.75 16 4.33424 16 6.375C16 7.62437 15.441 8.80059 14.3551 10.1292C13.2636 11.4645 11.6927 12.8919 9.75163 14.6521L9.75091 14.6528L9 15.3363L8.24909 14.6528Z"
                      stroke="white"
                    />
                  </svg> */}

                <LikeButton likedByMe={review?.likedByMe as boolean} likesCount={review?.likesCount as number} resourceId={review?.id as string} resourceType={"review"} textClassName={cn(variant === "compact" ? "text-xs sm:font-semibold text-[#e6e6fa]/90 top-0.5" : "sm:top-0")} iconClassName={cn(variant === "compact" && "")} data-sentry-element="LikeButton" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />

                {/* <span>{formatToShortenNumber(likesCount)}</span> */}

                <Link href={`${process.env.NEXT_PUBLIC_BASE_URL ?? ""}/books/${review?.book?.slug ?? ""}/reviews/${review?.user?.username ?? ""}`} className="flex items-center gap-1 -space-x-1.5 group" data-sentry-element="Link" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
                  <div className="sm:size-8 max-sm:mr-1 sm:group-hover:bg-white/[4%] rounded-[9999px] flex items-center justify-center">
                    <svg width={cn(variant === "compact" ? "16" : "18")} height={cn(variant === "compact" ? "16" : "18")} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
                      <path d="M8.99956 15.75C10.4718 15.7497 11.9035 15.268 13.0766 14.3785C14.2496 13.489 15.0997 12.2403 15.4973 10.8228C15.8948 9.40531 15.8181 7.89669 15.2787 6.52687C14.7393 5.15704 13.7668 4.00113 12.5095 3.23527C11.2522 2.46941 9.77897 2.1356 8.31434 2.28472C6.84971 2.43384 5.474 3.05771 4.39684 4.06125C3.31968 5.0648 2.60014 6.39298 2.34788 7.84341C2.09563 9.29384 2.32448 10.787 2.99956 12.0953L2.24956 15.75L5.90431 15C6.83131 15.4793 7.88431 15.75 8.99956 15.75Z" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" className={cn("sm:group-hover:stroke-[#50DDF2]", variant === "compact" ? "stroke-[#e6e6fa]/90" : "stroke-white/70")} data-sentry-element="path" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
                      <path d="M5.625 9H5.6325V9.0075H5.625V9ZM9 9H9.0075V9.0075H9V9ZM12.375 9H12.3825V9.0075H12.375V9Z" stroke="white" strokeWidth="2" strokeLinejoin="round" className={cn("sm:group-hover:stroke-[#50DDF2]", variant === "compact" ? "stroke-[#e6e6fa]/90" : "text-white/70")} data-sentry-element="path" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
                    </svg>
                  </div>
                  <span className={cn("sm:group-hover:text-[#50DDF2] font-semibold sm:font-normal text-sm", variant === "compact" && "text-xs sm:font-semibold")}>
                    {formatToShortenNumber(review?.commentsCount as number)}
                    <span className={cn(variant === "default" && "hidden")}>
                      {" "}
                      Comments
                    </span>
                  </span>{" "}
                </Link>

                <ShareButton shareUrl={`${process.env.NEXT_PUBLIC_BASE_URL}/books/${review?.book?.slug}/reviews/${review?.user?.username}`} trigger={<div className={cn("group text-sm font-semibold text-white sm:font-normal mr-1.5 cursor-pointer flex items-center justify-center p-0", variant === "compact" ? "sm:font-semibold sm:text-xs" : "sm:text-white/70")}>
                      <div className="sm:size-8 max-sm:mr-1 sm:group-hover:bg-white/[4%] rounded-[9999px] flex items-center justify-center">
                        <svg width={cn(variant === "compact" ? "16" : "18")} height={cn(variant === "compact" ? "16" : "18")} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.9333 6.46276V2.99609L17 9.06276L10.9333 15.1294V11.5761C6.6 11.5761 3.56667 12.9628 1.4 15.9961C2.26667 11.6628 4.86667 7.32943 10.9333 6.46276Z" fill={cn(variant === "default" ? "rgba(255, 255, 255, 0.7)" : "#e6e6fa")} className="sm:group-hover:fill-[#4EF386]" />
                        </svg>
                      </div>
                      <span className={cn("sm:group-hover:text-[#4EF386] text-white sm:text-white/70", variant === "compact" && "text-[#e6e6fa]/90")}>
                        Share
                      </span>
                    </div>} data-sentry-element="ShareButton" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />

                {/* Generate Shareable Image Button */}
                {/* <GenerateReviewImageButton review={review} /> */}

                <ReviewActionsMenu id={review?.id as string} bookSlug={review?.book?.slug as string} isMine={review?.user?.id === myUserId} shareUrl={`${process.env.NEXT_PUBLIC_BASE_URL}/books/${review?.book?.slug}/reviews/${review?.user?.username}`} reviewAuthorName={review?.user?.username as string} data-sentry-element="ReviewActionsMenu" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
              </div>
              {/* {username && (
                <AddToShelfButton
                  username={username}
                  initialShelves={review?.book?.myShelves as Shelf[]}
                  buttonClassName="w-[160px] justify-start"
                  bookId={review?.book?.id}
                  initialStatus={
                    review?.book?.myReadingStatus?.status as ReadingStatusEnum
                  }
                  className="max-w-fit mt-4"
                />
               )} */}
            </div>
          </div>
        </div>

        <div className={cn("sm:hidden", variant === "default" && "-mt-2")}>
          {false ? <Skeleton className="mt-2.5 h-[174px] w-[728px]" /> : <ReviewReadMore isSpoiler={review?.isSpoiler} content={review?.content} mobileLength={330} desktopLength={428} preTruncatedHtml={truncatedReview} preFullHtml={fullHtml} className={cn("mb-3 text-sm sm:text-base font-normal text-[#f4f4f4] sm:text-[#E6E6FA]", variant === "compact" && "text-[#e6e6fa]/90 max-sm:leading-[22px]", textClassName)} />}
          <div className={cn("flex items-center gap-5 sm:gap-10 text-sm font-semibold text-white sm:font-normal", variant === "default" && "-mt-2")}>
            <LikeButton likedByMe={review?.likedByMe as boolean} likesCount={review?.likesCount as number} resourceId={review?.id as string} resourceType={"review"} data-sentry-element="LikeButton" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
            <Link href={`${process.env.NEXT_PUBLIC_BASE_URL ?? ""}/books/${review?.book?.slug ?? ""}/reviews/${review?.user?.username ?? ""}`} className="flex items-center gap-1" data-sentry-element="Link" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx">
                <path d="M8.99956 15.75C10.4718 15.7497 11.9035 15.268 13.0766 14.3785C14.2496 13.489 15.0997 12.2403 15.4973 10.8228C15.8948 9.40531 15.8181 7.89669 15.2787 6.52687C14.7393 5.15704 13.7668 4.00113 12.5095 3.23527C11.2522 2.46941 9.77897 2.1356 8.31434 2.28472C6.84971 2.43384 5.474 3.05771 4.39684 4.06125C3.31968 5.0648 2.60014 6.39298 2.34788 7.84341C2.09563 9.29384 2.32448 10.787 2.99956 12.0953L2.24956 15.75L5.90431 15C6.83131 15.4793 7.88431 15.75 8.99956 15.75Z" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
                <path d="M5.625 9H5.6325V9.0075H5.625V9ZM9 9H9.0075V9.0075H9V9ZM12.375 9H12.3825V9.0075H12.375V9Z" stroke="white" strokeWidth="2" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
              </svg>
              <span className="text-sm font-semibold sm:font-normal">
                {formatToShortenNumber(review?.commentsCount as number)}
              </span>
            </Link>

            <ShareButton shareUrl={`${process.env.NEXT_PUBLIC_BASE_URL}/books/${review?.book?.slug}/reviews/${review?.user?.username}`} trigger={<div className="group text-sm font-semibold text-white sm:font-normal mr-1.5 cursor-pointer flex items-center justify-center p-0">
                  <div className="sm:size-8 max-sm:mr-1 sm:group-hover:bg-white/[4%] rounded-[9999px] flex items-center justify-center">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.9333 6.46276V2.99609L17 9.06276L10.9333 15.1294V11.5761C6.6 11.5761 3.56667 12.9628 1.4 15.9961C2.26667 11.6628 4.86667 7.32943 10.9333 6.46276Z" fill="white" />
                    </svg>
                  </div>
                  <span className=" text-white">Share</span>
                </div>} data-sentry-element="ShareButton" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />

            {/* Mobile Generate Shareable Image Button */}
            {/* <GenerateReviewImageButton review={review} /> */}

            <ReviewActionsMenu id={review?.id as string} bookSlug={review?.book?.slug as string} isMine={review?.user?.id === myUserId} shareUrl={`${process.env.NEXT_PUBLIC_BASE_URL}/books/${review?.book?.slug}/reviews/${review?.user?.username}`} reviewAuthorName={review?.user?.username as string} data-sentry-element="ReviewActionsMenu" data-sentry-source-file="TrendingReviewsThisWeekCard.tsx" />
          </div>
        </div>
      </CardContent>
    </Card>;
};
export default TrendingReviewsThisWeekCard;