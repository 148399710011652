"use client";

import { cn } from "@/lib/utils";
import React from "react";
import { Card, CardContent } from "../ui/card";
import { Skeleton } from "../ui/skeleton";
import useUIStore from "@/store/useUIStore";
import BookCover from "../shared/BookCover";
import { Book } from "@/graphql/generated/types";
import Link from "next/link";
import { AuthorNames } from "../shared/AuthorNames";
const Top10Books = ({
  books,
  className
}: {
  books: Book[];
  className?: string;
}) => {
  const isSkeletonLoading = useUIStore(state => state.isSkeletonLoading);
  return <div className={cn("m-0", className)} data-sentry-component="Top10Books" data-sentry-source-file="Top10Books.tsx">
      <div className="lg:mb-3 lg:border-b border-[#98c6f4]/20 pb-5 lg:pb-6">
        {isSkeletonLoading ? <Skeleton className="h-12 w-[250px]" /> : <h3 className="text-xl md:text-2xl font-bold md:font-semibold leading-8 text-white">
            Popular Books This Week
          </h3>}
      </div>
      <Card className="shadow-none" data-sentry-element="Card" data-sentry-source-file="Top10Books.tsx">
        <CardContent className="p-0 sm:max-lg:grid sm:max-lg:grid-cols-2 sm:max-lg:gap-x-10 sm:max-lg:gap-y-6 max-lg:flex max-lg:flex-col max-lg:gap-5" data-sentry-element="CardContent" data-sentry-source-file="Top10Books.tsx">
          {books?.map((book, index) => <TopBookCard key={book?.id} book={book} rank={index + 1} />)}
        </CardContent>
      </Card>
    </div>;
};
export default Top10Books;
interface TopBookProps {
  className?: string;
  book?: Book;
  rank: number;
}
const TopBookCard = ({
  book,
  rank,
  className
}: TopBookProps) => {
  const isSkeletonLoading = useUIStore(state => state.isSkeletonLoading);
  return <Link href={`/books/${book?.slug}`} data-sentry-element="Link" data-sentry-component="TopBookCard" data-sentry-source-file="Top10Books.tsx">
      <Card className={cn("rounded-[24px] lg:px-5 lg:py-4 shadow-none hover:cursor-pointer hover:bg-white/10", className)} data-sentry-element="Card" data-sentry-source-file="Top10Books.tsx">
        {isSkeletonLoading ? <Skeleton className="h-[84px] w-full rounded-[24px] bg-white/10" /> : <CardContent className="flex items-center gap-4 lg:gap-5 p-0">
            <span className="max-lg:hidden border-b-2 border-b-[#94A8F8] text-lg text-white">
              {rank.toString().padStart(2, "0")}
            </span>

            <div className="h-[120px] w-20 md:h-[150px] md:w-[100px] lg:h-[84px] lg:w-[56px] flex-shrink-0 overflow-hidden rounded-[4px] lg:rounded-[8px]">
              <BookCover sizes="(max-width: 640px) 80px, (max-width: 1024px) 100px, 56px" fallbackClassName="rounded-[4px] lg:rounded-[8px]" useDivInsteadOfLink fallBackSize={28} className="h-full w-full rounded-[4px] lg:rounded-[8px] object-cover object-center" book={book} />
            </div>
            <div className="flex flex-col gap-0.5">
              <div className="lg:hidden size-[38px] bg-[#232533] flex items-center justify-center rounded-[50%] mb-0.5">
                <span className=" text-base font-medium text-white">
                  {rank.toString().padStart(2, "0")}
                </span>
              </div>
              <div>
                <h4 className="lg:my-[9px] text-base lg:text-lg max-lg:line-clamp-1 font-bold lg:font-normal text-white">
                  {book?.title && book?.title?.length > 30 ? book?.title.slice(0, 30) + "..." : book?.title}
                </h4>
              </div>

              <div>
                <AuthorNames useDivInsteadOfLink authors={book?.authors} className="lg:hidden text-base font-normal text-[#adadad]" />
                {/* <p className="sm:hidden text-base font-normal text-[#adadad]">
                  {book?.authors &&
                    book?.authors?.length > 0 &&
                    book?.authors?.[0]?.name}
                 </p> */}
              </div>
            </div>
          </CardContent>}
      </Card>
    </Link>;
};