"use client";

// import Image from "next/image"
import React from "react";
import Link from "next/link";
import useUIStore from "@/store/useUIStore";
import { Skeleton } from "../ui/skeleton";
import { buttonVariants } from "../ui/button";
// import { useResponsive } from "@/hooks/useResponsive"

const BASE_IMAGE_URL = "https://images.kaguya.io/ui/home";
const Hero = () => {
  const isSkeletonLoading = useUIStore(state => state.isSkeletonLoading);
  // const { isMobile, isBigDesktop } = useResponsive()
  // let size: "sm" | "xl" | "2xl" = "xl"

  // if (isBigDesktop) {
  //   size = "2xl"
  // } else if (isMobile) {
  //   size = "sm"
  // }

  // const featuredImage = `/assets/home/home-${size}.webp`

  return <section className="relative overflow-hidden" data-sentry-component="Hero" data-sentry-source-file="Hero.tsx">
      <div className="relative flex max-h-screen min-h-[500px] sm:min-h-[699px] max-sm:w-[120%] w-full">
        {isSkeletonLoading ? null :
      // <Image
      //   src="/assets/home/featured-image.png"
      //   width={2000}
      //   height={999}
      //   className="w-full object-cover"
      //   alt="featured cover"
      // />

      <picture>
            <source media="(min-width: 1536px)" srcSet={`${BASE_IMAGE_URL}/home-2xl.webp`} type="image/webp" className="w-full h-full object-cover" />
            <source media="(min-width: 1024px)" srcSet={`${BASE_IMAGE_URL}/home-xl.webp`} type="image/webp" className="w-full h-full object-cover" />
            <img srcSet={`${BASE_IMAGE_URL}/home-sm.webp`} alt="featured cover" fetchPriority="high" className="w-full max-sm:scale-125 max-sm:-ml-[19%] h-full object-cover " width="2000" height="999" loading="eager" />
          </picture>}
        <div className="absolute inset-0 bg-black/60 sm:bg-black/40 p-0"></div>
        <div className="absolute max-sm:-ml-[8%] w-full left-1/2 top-1/2 z-10 flex -translate-x-1/2 -translate-y-1/2 sm:-translate-y-1/2 flex-col items-center justify-center gap-14 sm:gap-10 text-white">
          <div className="text-center max-lg:flex max-lg:flex-col max-lg:gap-2 text-[28px] md:text-[32px] lg:text-[44px] font-semibold lg:font-bold leading-5 lg:leading-snug -tracking-[1%]">
            {isSkeletonLoading ? <Skeleton className="h-6 sm:h-14 w-[338px] sm:w-[604px]" /> : <span>Track books you&apos;ve read.</span>}
            <br />
            {isSkeletonLoading ? <Skeleton className="h-6 sm:h-14 -mt-10 w-[338px] sm:w-[604px]" /> : <span>Save your future reads.</span>}
            <br />
            {isSkeletonLoading ? <Skeleton className="h-6 sm:h-12 -mt-10 w-[338px] sm:w-[604px]" /> : <span>Discover new favorites.</span>}
          </div>
          {isSkeletonLoading ? <Skeleton className="h-12 w-[200px]" /> : <Link className={buttonVariants({
          variant: "primary"
        }) + "rounded-[8px] bg-[#5069ce] max-sm:w-fit max-sm:px-16 max-sm:py-4 max-w-[260px] text-base font-bold sm:text-[18px] px-16 sm:px-8 py-4 sm:py-3.5 sm:font-semibold text-white"} href="/signup" rel="nofollow">
              Get started — it&apos;s free!
            </Link>}
        </div>
      </div>
      <div className="max-sm:hidden absolute left-1/2 -mt-56 h-[288px] w-[120%] -translate-x-1/2 bg-[#10111e]" style={{
      filter: "blur(60px)"
    }}></div>
    </section>;
};
export default Hero;